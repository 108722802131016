import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  allCampaigns: { campaigns: [], count: 0 },
  campaignLeads: { count: 0, items: [], campaignStats: {} },
  campaignDetails: { campaignSteps: [] },
  additionalVariables: [],
  csvInfo: {},
  allCampaignNames: [],
  draftCampaignId: undefined,
  draftCampaignUpdateCounter: 0,
  leadsByAllCampaigns: [],
  selectedNode: {},
  selectedTab: "1",
  savedSequences: {
    items: [],
    count: 0,
  },
  teamTemplate: {
    items: [],
    count: 0,
  },
  stepTemplates: {
    items: [],
    count: 0,
  },
  allActiveCampaigns: [],
}

export default function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_ALL_CAMPAIGNS: {
      return { ...state, allCampaigns: action.allCampaigns }
    }

    case ACTIONS.GET_ALL_ACTIVE_CAMPAIGNS: {
      return { ...state, allActiveCampaigns: action.allActiveCampaigns }
    }

    case ACTIONS.GET_LEADS_BY_CAMPAIGN: {
      return {
        ...state,
        campaignLeads: action.campaignLeads,
      }
    }

    case ACTIONS.GET_CAMPAIGN_DETAILS: {
      return {
        ...state,
        campaignDetails: action.campaignDetails,
      }
    }
    case ACTIONS.CLEAR_CAMPAIGN_DETAILS: {
      return {
        ...state,
        campaignDetails: { campaignSteps: [] },
      }
    }

    case ACTIONS.GET_CAMPAIGN_STEPS: {
      return {
        ...state,
        campaignDetails: { ...state.campaignDetails, campaignSteps: action.campaignSteps },
      }
    }

    case ACTIONS.SET_ADDITIONAL_VARIABLES: {
      return {
        ...state,
        additionalVariables: action.additionalVariables,
      }
    }
    case ACTIONS.CLEAR_ADDITIONAL_VARIABLES: {
      return {
        ...state,
        additionalVariables: [],
      }
    }

    case ACTIONS.GET_CAMPAIGN_NAMES: {
      return {
        ...state,
        allCampaignNames: action.allCampaignNames,
      }
    }

    case ACTIONS.CHANGE_LEAD_ACTIVITY: {
      return {
        ...state,
        campaignLeads: {
          ...state.campaignLeads,
          items: action.allLeads,
        },
      }
    }

    case ACTIONS.DELETE_LEADS: {
      return {
        ...state,
        campaignLeads: {
          ...state.campaignLeads,
          items: action.allLeads,
        },
      }
    }

    case ACTIONS.DELETE_CAMPAIGNS: {
      return {
        ...state,
        allCampaigns: {
          ...state.allCampaigns,
          campaigns: action.allCampaigns,
        },
      }
    }

    case ACTIONS.GET_CSV_INFO: {
      return {
        ...state,
        csvInfo: action.csvInfo,
      }
    }

    case ACTIONS.SELECT_DRAFT_CAMPAIGN: {
      return {
        ...state,
        draftCampaignId: action.draftCampaignId,
      }
    }

    case ACTIONS.UPDATE_DRAFT_CAMPAIGN: {
      return {
        ...state,
        draftCampaignUpdateCounter: action.draftCampaignUpdateCounter,
      }
    }

    case ACTIONS.SET_SELECTED_NODE: {
      return {
        ...state,
        selectedNode: action.selectedNode,
      }
    }

    case ACTIONS.GET_LEADS_BY_ALL_CAMPAIGNS: {
      return { ...state, leadsByAllCampaigns: action.leadsByAllCampaigns }
    }

    case ACTIONS.SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.selectedTab,
      }
    }

    case ACTIONS.GET_SAVED_SEQUENCES: {
      return {
        ...state,
        savedSequences: {
          items: [
            ...(action.init ? [] : state.savedSequences.items),
            ...action.savedSequences.items,
          ],
          count: action.savedSequences.count,
        },
      }
    }

    case ACTIONS.GET_TEAM_TEMPLATE: {
      return {
        ...state,
        teamTemplate: {
          items: [...(action.init ? [] : state.teamTemplate.items), ...action.teamTemplate.items],
          count: action.teamTemplate.count,
        },
      }
    }

    case ACTIONS.REPLACE_TEAM_TEMPLATE: {
      return {
        ...state,
        teamTemplate: {
          items: action.teamTemplate,
          count: state.teamTemplate.count - 1,
        },
      }
    }

    case ACTIONS.CLEAR_CSV_INFO: {
      return {
        ...state,
        csvInfo: {},
      }
    }

    case ACTIONS.GET_STEP_TEMPLATES: {
      return {
        ...state,
        stepTemplates: {
          items: [...(action.init ? [] : state.stepTemplates.items), ...action.stepTemplates.items],
          count: action.stepTemplates.count,
        },
      }
    }

    case ACTIONS.REPLACE_STEP_TEMPLATES: {
      return {
        ...state,
        stepTemplates: {
          items: action.stepTemplates,
          count: state.stepTemplates.count - 1,
        },
      }
    }

    case ACTIONS.CLEAR_ALL_CAMPAIGN_NAMES: {
      return {
        ...state,
        allCampaignNames: initialState.allCampaignNames,
      }
    }

    case ACTIONS.USER_LOGOUT: {
      return initialState
    }

    default:
      return { ...state }
  }
}
