import "./Overlay.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Stepper from "../../atoms/Stepper"
import TabBar from "../../atoms/TabBar"
import Footer, { FooterVariant } from "../../molecules/Footer"
import InlineNotification, { InlineNotificationVariant } from "../../molecules/InlineNotification"

export const OverlayPages = {
  SEQUENCE_PAGE: "sequencePage",
  STREAM_PAGE: "streamPage",
}

const Overlay = ({
  children,
  steps,
  title,
  footerButtonFunctions,
  activeStep,
  disabledButtons,
  tabBarItems,
  pageType,
  disableEmailSettingsForDiscoverLeadsOnly,
  isSendLeadBackToCampaign,
  footerVariant,
}) => {
  const overlayContentClassName = classNames("overlay-content", {
    "overlay-content-sequence-page": pageType === OverlayPages.SEQUENCE_PAGE,
    "overlay-content-linkedin-stream-page": pageType === OverlayPages.STREAM_PAGE,
  })

  const overlayHeaderClassName = classNames("overlay-header", {
    "overlay-header-linkedin-stream-page": pageType === OverlayPages.STREAM_PAGE,
  })

  return (
    <div className="overlay">
      <div className={overlayHeaderClassName}>
        <h1>{title}</h1>
        {disableEmailSettingsForDiscoverLeadsOnly && (
          <div className="email-settings-warning-message-wrapper">
            <div className="email-settings-content">
              <InlineNotification
                type={InlineNotificationVariant.WARNING}
                label={
                  <span>
                    Email settings changes aren't allowed when{" "}
                    <span className="campaign-type-span">Discover leads only</span> campaign is on.
                  </span>
                }
              />
            </div>
          </div>
        )}
        {steps && <Stepper steps={steps} activeStep={activeStep} isOverlay />}
        {tabBarItems?.length > 0 && (
          <div className="overlay-tab-bar">
            <TabBar items={tabBarItems} />
          </div>
        )}
      </div>
      <div className={overlayContentClassName}>{children}</div>

      {footerButtonFunctions?.length > 0 && (
        <Footer
          type={footerVariant || FooterVariant.PROGRESS_LARGE}
          buttons={[
            footerButtonFunctions?.[0] && {
              label: "Cancel",
              onClick: footerButtonFunctions[0],
              disabled: disabledButtons?.[0] ?? false,
            },
            footerButtonFunctions?.[1] && {
              label: isSendLeadBackToCampaign ? "Cancel" : "Previous",
              onClick: footerButtonFunctions[1],
              disabled: disabledButtons?.[1] ?? false,
            },
            footerButtonFunctions?.[2] && {
              label: isSendLeadBackToCampaign ? "Send" : "Next",
              onClick: footerButtonFunctions[2],
              disabled: disabledButtons?.[2] ?? false,
            },
            footerButtonFunctions?.[3] && {
              label: "Cancel",
              onClick: footerButtonFunctions[3],
              disabled: disabledButtons?.[3] ?? false,
            },
            footerButtonFunctions?.[4] && {
              label: "Save",
              onClick: footerButtonFunctions[4],
              disabled: disabledButtons?.[4] ?? false,
            },
          ]}
        />
      )}
    </div>
  )
}

Overlay.propTypes = {
  title: PropTypes.string.isRequired,
  activeStep: PropTypes.number,
  tabBarItems: PropTypes.arrayOf(Object),
  footerButtonFunctions: PropTypes.arrayOf(PropTypes.func),
  disableEmailSettingsForDiscoverLeadsOnly: PropTypes.bool,
  steps: PropTypes.arrayOf(Object),
  pageType: PropTypes.oneOf(Object.values(OverlayPages)),
  isSendLeadBackToCampaign: PropTypes.bool,
  footerVariant: PropTypes.oneOf(Object.values(FooterVariant)),
}

Overlay.defaultProps = {
  activeStep: 0,
  tabBarItems: [],
  footerButtonFunctions: [],
  disableEmailSettingsForDiscoverLeadsOnly: false,
  steps: [],
  isSendLeadBackToCampaign: false,
  footerVariant: undefined,
  pageType: undefined,
}
export default Overlay
